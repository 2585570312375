import { FC } from 'react'
import { ChildrenMinistryMailToLink } from '../common/MailToLink'
import Paragraph from '../common/Paragraph'
import { CHILDREN_MINISTRY_SECTION_DESCRIPTION_1 } from './strings'

const ChildrensMinistry: FC = () => {
  return (
    <>
      <Paragraph>{CHILDREN_MINISTRY_SECTION_DESCRIPTION_1}</Paragraph>
      <Paragraph>
        To that end, we teach lessons based on curricula that is developed by
        Truth78, an organization whose mission is to “inspire and equip the
        church and the home for comprehensive discipleship of the next
        generation.” (
        <a href="https://www.truth78.org/mission">
          https://www.truth78.org/mission
        </a>
        )
      </Paragraph>
      <Paragraph>
        For more information, reach out to <ChildrenMinistryMailToLink />.
      </Paragraph>
    </>
  )
}

export default ChildrensMinistry
