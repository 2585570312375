/* eslint-disable react/no-danger */
import { Typography, Grid, Button } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import customTheme from '../../theme'
import LEADERSHIP_DATA from './leadership-data'
import { LEADERSHIP_CONTACT } from '../strings'
import { AdminMailToLink } from '../../common/MailToLink'

const LeadershipProfilePicture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;

  // Center image
  display: block;
  margin: 0 auto 10px;

  ${customTheme.breakpoints.down('sm')} {
    width: 300px;
    height: 300px;
  }
`

const LeadershipPastorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LeadershipContact = styled.div`
  text-align: center;
  padding-top: 5vh;
`

const LeadershipSection: FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ sm: 4, md: 12 }}
        justifyContent="center"
      >
        {LEADERSHIP_DATA.map((leader) => (
          <Grid item xs={2} sm={4} md={4} key={leader.id}>
            <LeadershipPastorContainer>
              <LeadershipProfilePicture src={leader.image} alt="profile" />
              <Typography align="center" variant="h3">
                {leader.name}
              </Typography>
              <Typography align="center" variant="h6">
                {leader.role}
              </Typography>
              <Button size="large" onClick={() => navigate(leader.id)}>
                More
              </Button>
            </LeadershipPastorContainer>
          </Grid>
        ))}
      </Grid>
      <LeadershipContact>
        <Typography>
          {LEADERSHIP_CONTACT}
          <AdminMailToLink />
        </Typography>
      </LeadershipContact>
    </>
  )
}

export default LeadershipSection
