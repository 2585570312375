import { FC } from 'react'
import { PlanningMailToLink } from '../common/MailToLink'
import Paragraph from '../common/Paragraph'

const MensMinistry: FC = () => {
  return (
    <>
      <Paragraph>
        Men&#39;s Ministry meets quarterly at church. Meetings are open to all
        ages and visitors and begin with a time of praise, a sermon from a guest
        speaker, then a time of discussion and small groups covering the sermon
        preached.
      </Paragraph>
      <Paragraph>
        Men&#39;s Ministry also hosts our Men&#39;s Weekender retreat every two
        years in the Fall. Contact <PlanningMailToLink /> for more details.
      </Paragraph>
    </>
  )
}

export default MensMinistry
