/* eslint-disable prettier/prettier */
import { useMediaQuery } from '@mui/material'
import { FC } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import ResponsiveContainer from '../common/ResponsiveContainer'
import SecondaryNavigationTabs from '../common/SecondaryNavigationTabs'
import SectionHeader from '../common/SectionHeader'
import { NAVIGATION_TAB_OPTIONS } from '../nav/tab-types'
import customTheme from '../theme'

const Ministry: FC = () => {
  const matches = useMediaQuery(customTheme.breakpoints.down('sm'))

  const navigationSubOptions = NAVIGATION_TAB_OPTIONS.find(
    (tab) => tab.label === 'Ministries'
  )

  // College ministry page is an exception so banner image is not put into responsive container.
  const currentUrl = useLocation()
  if (currentUrl.pathname === '/ministry/college') {
    return (
      <>
        {!matches && (
          <SecondaryNavigationTabs
            subOptions={navigationSubOptions?.subOptions || []}
          />
        )}
        <Outlet />
      </>
    )
  }

  return (
    <>
      {!matches && (
        <SecondaryNavigationTabs
          subOptions={navigationSubOptions?.subOptions || []}
        />
      )}
      <SectionHeader subOptions={navigationSubOptions?.subOptions} />
      <ResponsiveContainer>
        <Outlet />
      </ResponsiveContainer>
    </>
  )
}

export default Ministry
