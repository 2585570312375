import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom'
import Footer from '../footer'
import NavBar from '../nav'
import { NOT_FOUND_SUBTITLE, NOT_FOUND_TITLE } from './string'

const ErrorPage: FC = () => {
  const error = useRouteError()

  let errorText = 'An error has occurred'
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      errorText = NOT_FOUND_TITLE
    }
  }

  return (
    <Stack direction="column" sx={{ height: '100vh' }}>
      <NavBar />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '400px' }}
      >
        <Typography variant="h1" gutterBottom>
          {errorText}
        </Typography>
        <Typography variant="h4">
          {NOT_FOUND_SUBTITLE} <Link to="/">home</Link>
        </Typography>
      </Stack>
      <Footer />
    </Stack>
  )
}

export default ErrorPage
