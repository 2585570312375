import { Tabs, Tab } from '@mui/material'
import { FC, useState, useEffect, SyntheticEvent } from 'react'
import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'
import customTheme from '../theme'
import { SubOptionProps } from '../nav/tab-types'

const StyledTabs = styled(Tabs)(() => ({
  backgroundColor: customTheme.palette.neutral.dark,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& a.Mui-selected': {
    background: 'rgba(70, 98, 37, 0.1)',
    borderRadius: '6px',
    margin: '12px',
  },
  '& a.color': '#474747',
  justifyContent: 'center',
  '& .MuiTabs-scroller': {
    flexGrow: '0',
  },
}))

type SecondaryNavigationTabsProps = {
  /**
   * Map of url path to bread crumb name
   */
  subOptions: SubOptionProps[]
}

const SecondaryNavigationTabs: FC<SecondaryNavigationTabsProps> = ({
  subOptions,
}) => {
  const currentUrl = useLocation()

  const getTabIndexFromPath = (path: string): number | undefined =>
    subOptions.findIndex((element) => {
      return path.includes(element.href)
    })

  const [tabValue, setTabValue] = useState(
    getTabIndexFromPath(currentUrl.pathname)
  )

  useEffect(() => {
    setTabValue(getTabIndexFromPath(currentUrl.pathname))
  }, [currentUrl])

  const handleTabChange = (event: SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue)
  }

  return (
    <StyledTabs
      value={tabValue}
      onChange={handleTabChange}
      indicatorColor="primary"
      variant="scrollable"
    >
      {subOptions.map((value) => (
        <Tab
          component={Link}
          to={value.href}
          label={
            <span style={{ color: '#474747', textTransform: 'none' }}>
              {value.label}
            </span>
          }
          key={value.label}
        />
      ))}
    </StyledTabs>
  )
}

export default SecondaryNavigationTabs
