import { Typography } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import H5AudioPlayer from 'react-h5-audio-player'
import { Sermon } from '../client/types/sermon'
import 'react-h5-audio-player/lib/styles.css'

const AudioPlayerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;

  .rhap_container {
    padding: 30px 13%;

    box-shadow: 0px -2px 4px -1px rgb(0 0 0 / 10%),
      0px -4px 5px 0px rgb(0 0 0 / 6%), 0px -1px 10px 0px rgb(0 0 0 / 4%);
  }
`

type AudioHeaderProps = {
  sermon?: Sermon
}

const AudioHeader: FC<AudioHeaderProps> = ({ sermon }: AudioHeaderProps) => {
  return (
    <Typography variant="h2" align="center">
      {sermon?.title}
    </Typography>
  )
}

type SermonAudioPlayerProps = {
  /**
   * The sermon to play the audio for.
   */
  selectedSermon?: Sermon
}

const SermonAudioPlayer: FC<SermonAudioPlayerProps> = ({ selectedSermon }) => {
  if (!selectedSermon) {
    return null
  }

  return (
    <AudioPlayerContainer>
      <H5AudioPlayer
        src={selectedSermon?.audioLocation}
        header={<AudioHeader sermon={selectedSermon} />}
        progressJumpStep={15000}
        customAdditionalControls={[]}
        autoPlay
      />
    </AudioPlayerContainer>
  )
}

export default SermonAudioPlayer
