import { Event, AccessTime, LocationOn, Mail } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { FC, ReactNode } from 'react'
import LabelIcon from '../common/LabelIcon'

type TimeAndLocationSectionProps = {
  upcomingDates: string
  duration: string
  location: string
  email: ReactNode
}

const DetailsSection: FC<TimeAndLocationSectionProps> = ({
  upcomingDates,
  duration,
  location,
  email,
}) => {
  return (
    <Stack direction="column" sx={{ marginBottom: '30px' }}>
      <LabelIcon icon={<Event />} sx={{ marginBottom: '10px' }}>
        {upcomingDates}
      </LabelIcon>
      <LabelIcon icon={<AccessTime />} sx={{ marginBottom: '10px' }}>
        {duration}
      </LabelIcon>
      <LabelIcon icon={<LocationOn />} sx={{ marginBottom: '10px' }}>
        {location}
      </LabelIcon>
      <LabelIcon icon={<Mail />}>{email}</LabelIcon>
    </Stack>
  )
}

export default DetailsSection
