import { Typography } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import InfoCard from '../common/InfoCard'
import { MINISTRY_PARTNER_LIST } from './strings'

const MinistryPartnerContainer = styled.div`
  a {
    text-decoration: none;
  }
`

const StyledAnswer = styled(Typography)`
  ul {
    margin-top: 10px;
    list-style: circle;

    li {
      margin-left: 30px;
    }
  }
`

const MinistryPartners: FC = () => {
  return (
    <MinistryPartnerContainer>
      {MINISTRY_PARTNER_LIST.map((partner) => {
        return (
          <InfoCard key={partner.title}>
            <Typography variant="h3" gutterBottom>
              <a href={partner.link} target="_blank" rel="noreferrer">
                {partner.title}
              </a>
            </Typography>
            <StyledAnswer
              variant="body1"
              dangerouslySetInnerHTML={{ __html: partner.description!! }}
            />
          </InfoCard>
        )
      })}
    </MinistryPartnerContainer>
  )
}

export default MinistryPartners
