import { Typography } from '@mui/material'
import { FC, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

const ConstitutionContainer = styled.div`
  h1 {
    font-size: 2.5rem;
    line-height: 1.25;

    margin-bottom: 30px;
  }

  h2 {
    font-size: 1.5rem;

    margin-bottom: 15px;
  }

  h3 {
    font-size: 1.25rem;

    margin-bottom: 10px;
  }

  p {
    margin: 0 0 15px 0;
  }

  article {
    margin: 0 0 30px 70px;
    border-bottom: 1px solid #000000;
  }

  section {
    margin: 0 0 20px 0;
  }

  ul {
    list-style: circle;
    margin: 0 0 15px 30px;

    li {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  article {
    scroll-margin-top: 100px;
  }

  section {
    scroll-margin-top: 100px;
  }

  li {
    list-style-type: none;
  }

  #toc {
    height: 60%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 200px;
    left: 0;
    overflow-y: scroll;
  }

  @media (max-width: 1100px) {
    #toc {
      display: none;
    }
  }
`

// Returns the current "active" element by id. Active is defined as being the
// most prominent element on the viewport.
export function useHeadsObserver() {
  const observer = useRef<IntersectionObserver | null>(null)
  const [activeId, setActiveId] = useState('')

  useEffect(() => {
    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id)
        }
      })
    }

    observer.current = new IntersectionObserver(handleObserver, {
      rootMargin: '-20% 0px -40% 0%',
    })

    const elements = document.querySelectorAll('article, section')
    elements.forEach((elem) => observer.current?.observe(elem))
    return () => observer.current?.disconnect()
  }, [])

  return { activeId }
}

const ConstitutionSection: FC = () => {
  const { activeId } = useHeadsObserver()
  return (
    <ConstitutionContainer>
      <div id="toc">
        <ul>
          <li>
            <a
              href="#preamble"
              style={{
                fontWeight: activeId === 'preamble' ? 'bold' : 'normal',
              }}
            >
              Preamble
            </a>
          </li>
          <li>
            <a
              href="#purpose"
              style={{
                fontWeight: activeId === 'purpose' ? 'bold' : 'normal',
              }}
            >
              Article 1 - Purpose
            </a>
          </li>
          <li>
            <a
              href="#membership"
              style={{
                fontWeight: activeId === 'membership' ? 'bold' : 'normal',
              }}
            >
              Article 2 - Church Membership
            </a>
            <ul>
              <li>
                <a
                  href="#2.1"
                  style={{
                    fontWeight: activeId === '2.1' ? 'bold' : 'normal',
                  }}
                >
                  Section 1 - Qualifications
                </a>
              </li>
              <li>
                <a
                  href="#2.2"
                  style={{
                    fontWeight: activeId === '2.2' ? 'bold' : 'normal',
                  }}
                >
                  Section 2 - Admission of Members
                </a>
              </li>
              <li>
                <a
                  href="#2.3"
                  style={{
                    fontWeight: activeId === '2.3' ? 'bold' : 'normal',
                  }}
                >
                  Section 3 - Denial of Membership
                </a>
              </li>
              <li>
                <a
                  href="#2.4"
                  style={{
                    fontWeight: activeId === '2.4' ? 'bold' : 'normal',
                  }}
                >
                  Section 4 - Duties and Privileges of Membership
                </a>
              </li>
              <li>
                <a
                  href="#2.5"
                  style={{
                    fontWeight: activeId === '2.5' ? 'bold' : 'normal',
                  }}
                >
                  Section 5 - Voting Rights
                </a>
              </li>
              <li>
                <a
                  href="#2.6"
                  style={{
                    fontWeight: activeId === '2.6' ? 'bold' : 'normal',
                  }}
                >
                  Section 6 - Church Discipline
                </a>
              </li>
              <li>
                <a
                  href="#2.7"
                  style={{
                    fontWeight: activeId === '2.7' ? 'bold' : 'normal',
                  }}
                >
                  Section 7 - Termination of Membership
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#meetings"
              style={{
                fontWeight: activeId === 'meetings' ? 'bold' : 'normal',
              }}
            >
              Article 3 - Meetings
            </a>
            <ul>
              <li>
                <a
                  href="#3.1"
                  style={{
                    fontWeight: activeId === '3.1' ? 'bold' : 'normal',
                  }}
                >
                  Section 1 - Worship Meetings
                </a>
              </li>
              <li>
                <a
                  href="#3.2"
                  style={{
                    fontWeight: activeId === '3.2' ? 'bold' : 'normal',
                  }}
                >
                  Section 2 - Members&apos; Meetings
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#elders"
              style={{
                fontWeight: activeId === 'elders' ? 'bold' : 'normal',
              }}
            >
              Article 4 - Elders
            </a>
          </li>
          <li>
            <a
              href="#officers"
              style={{
                fontWeight: activeId === 'officers' ? 'bold' : 'normal',
              }}
            >
              Article 5 - Other Officers and Committees
            </a>
            <ul>
              <li>
                <a
                  href="#5.1"
                  style={{
                    fontWeight: activeId === '5.1' ? 'bold' : 'normal',
                  }}
                >
                  Section 1 - Summary
                </a>
              </li>
              <li>
                <a
                  href="#5.2"
                  style={{
                    fontWeight: activeId === '5.2' ? 'bold' : 'normal',
                  }}
                >
                  Section 2 - Deacon
                </a>
              </li>
              <li>
                <a
                  href="#5.3"
                  style={{
                    fontWeight: activeId === '5.3' ? 'bold' : 'normal',
                  }}
                >
                  Section 3 - Treasurer
                </a>
              </li>
              <li>
                <a
                  href="#5.4"
                  style={{
                    fontWeight: activeId === '5.4' ? 'bold' : 'normal',
                  }}
                >
                  Section 4 - Councils and Committees
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#ordination"
              style={{
                fontWeight: activeId === 'ordination' ? 'bold' : 'normal',
              }}
            >
              Article 6 - Ordination, Licensing, and Commissioning
            </a>
            <ul>
              <li>
                <a
                  href="#6.1"
                  style={{
                    fontWeight: activeId === '6.1' ? 'bold' : 'normal',
                  }}
                >
                  Section 1 - Ordination
                </a>
              </li>
              <li>
                <a
                  href="#6.2"
                  style={{
                    fontWeight: activeId === '6.2' ? 'bold' : 'normal',
                  }}
                >
                  Section 2 - Licensing
                </a>
              </li>
              <li>
                <a
                  href="#6.3"
                  style={{
                    fontWeight: activeId === '6.3' ? 'bold' : 'normal',
                  }}
                >
                  Section 3 - Commissioning
                </a>
              </li>
              <li>
                <a
                  href="#6.4"
                  style={{
                    fontWeight: activeId === '6.4' ? 'bold' : 'normal',
                  }}
                >
                  Section 4 - Marriages and Weddings
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#disputes"
              style={{
                fontWeight: activeId === 'disputes' ? 'bold' : 'normal',
              }}
            >
              Article 7 - Disputes Resolution
            </a>
          </li>
          <li>
            <a
              href="#amendments"
              style={{
                fontWeight: activeId === 'amendments' ? 'bold' : 'normal',
              }}
            >
              Article 8 - Amendments
            </a>
          </li>
        </ul>
      </div>
      <div>
        <article id="preamble">
          <Typography variant="h1">
            <a href="#preamble">Preamble</a>
          </Typography>
          <Typography variant="body1">
            We, the members of CrossLife Bible Church (the “Church”), having
            searched the Scriptures, have recognized the need to reconstitute
            ourselves to more closely conform to His Will and prepare ourselves
            for greater efforts in His Name.
          </Typography>
          <Typography variant="body1">
            Now therefore we, the members of the Church, subject to and not
            inconsistent with the Church&apos;s Bylaws, do hereby organize
            ourselves in accord with this Constitution as our articles of
            governance, to be interpreted at all times to reflect the character
            of and bring glory to Jesus Christ, as revealed in the Holy Bible
            and articulated in the standards set forth in the Statement of Faith
            and Covenant of this Church.
          </Typography>
        </article>
        <article id="purpose">
          <Typography variant="h1">
            <a href="#purpose">Article 1 - Purpose</a>
          </Typography>
          <Typography variant="body1">
            The purpose of this church is to glorify God by trusting the gospel
            of the Lord Jesus Christ and consequently obeying the Lord by
            submitting to the Scriptures, evangelizing both locally and
            overseas, encouraging one another, discipling the saints, practicing
            the ordinances of baptism and communion, and providing for the needs
            of the members of the church who have served faithfully.
          </Typography>
        </article>
        <article id="membership">
          <Typography variant="h1">
            <a href="#membership">Article 2 - Church Membership</a>
          </Typography>
          <section id="2.1">
            <Typography variant="h2">Section 1 - Qualifications</Typography>
            <Typography variant="body1">
              In order to become a member of the Church a person must believe in
              the gospel of Jesus Christ, be baptized, and show evidence of true
              faith, including obedience to the Holy Scriptures in both personal
              and communal life as outlined in the membership covenant.
            </Typography>
          </section>
          <section id="2.2">
            <Typography variant="h2">
              Section 2 - Admission of Members
            </Typography>
            <Typography variant="body1">
              To be admitted into church membership, an applicant must agree to
              the Church Covenant and the doctrinal statement, and complete the
              membership classes and requisite readings. Afterwards, he/she will
              be interviewed by chosen members of the church who will assess the
              personal profession of faith and the desire to adhere to the rules
              of community life. The notes from the interview will then be
              reviewed by the Elders. If any member of the Church expresses
              concerns about the authenticity of an applicant&apos;s faith, the
              Elders will reexamine the latter&apos;s qualification for
              membership.
            </Typography>
          </section>
          <section id="2.3">
            <Typography variant="h2">
              Section 3 - Denial of Membership
            </Typography>
            <Typography variant="body1">
              If, upon review of an application for membership or after meeting
              with a prospective church member, the Elders determine that the
              applicant does not confess Jesus Christ as his or her Lord and
              Savior, or that there is a lack of evidence of a godly lifestyle,
              membership shall be denied. The decision made by the Elders shall
              be final and there shall be no appeal to any court from that
              decision.
            </Typography>
          </section>
          <section id="2.4">
            <Typography variant="h2">
              Section 4 - Duties and Privileges of Membership
            </Typography>
            <Typography variant="body1">
              In accord with the duties specified in the Church Covenant,
              members will be privileged and expected to participate in and
              contribute to the ministry and life of the church, consistent with
              the gifts, time, and material resources each has received from
              God. Only those who are members will be entitled to serve in the
              ministries of the church; non-members may serve on an ad-hoc basis
              with the approval of the Elders.
            </Typography>
            <Typography variant="body1">
              Members will also be permitted and expected to participate in the
              monthly observance of communion and the membership meetings where
              the concerns and announcements of church life are addressed and
              given respectively.
            </Typography>
          </section>
          <section id="2.5">
            <Typography variant="h2">Section 5 - Voting Rights</Typography>
            <Typography variant="body1">
              Those admitted to church membership do not constitute a
              legislative body, nor do they constitute members of the
              Corporation, and they cannot vote, pass resolutions binding upon
              the Corporation, nor shall they have any equity in the real
              property of the Corporation, or rights to vote on its disposal.
              Said property of the Corporation is dedicated to religious and
              charitable purposes as outlined in the Articles of Incorporation.
              Stated again, church membership does not carry any voting power in
              any shape or form.
            </Typography>
          </section>
          <section id="2.6">
            <Typography variant="h2">
              Section 6 - On Church Discipline:
            </Typography>
            <Typography variant="body1">
              The Scriptures outline church discipline for various scenarios
              against Christians who have practically denied their profession of
              faith in the Gospel by living a life of unrepentant sin.
            </Typography>
            <Typography variant="body1">
              The purpose of church discipline include the following:
            </Typography>
            <ul>
              <li>
                <Typography variant="body1">
                  repentance, reconciliation, and the spiritual growth of the
                  disciplined individual (c.f. Ephesians 6:4; 1 Timothy 3:4-5;
                  Hebrews 12:1-11; Psalm 119:115; Proverbs 17:10; 25:12; 27:5;
                  Ecclesiastes 7:5; Matthew 7:26-27; 18:15-17; Luke 17:3; Acts
                  2:40; 1 Corinthians 5:5; Galatians 6:1-5; 2 Thessalonians 3:6,
                  14-15; 1 Timothy 1:20; Titus 1:13-14; James 1:22);
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  the purity and the protection of the church as a whole (1
                  Corinthians 5:6-7; 2 Corinthians 13:10; Ephesians 5:27; 2 John
                  10; Jude 24);
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  the good of our corporate witness to non-Christians (Proverbs
                  28:7; Matthew 5:13-16; John 13:34-35; Acts 5:1-14; Ephesians
                  5:11; 1 Timothy 3:7; 2 Peter 2:2; 1 John 3:10); and
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  supremely the glory of God by reflecting His holy character
                  (Deuteronomy 5:11; 1 Kings 11:2; 2 Chronicles 19:2; Ezra 6:21;
                  Nehemiah 9:2; Isaiah 52:11; Ezekiel 36:20; Matthew 5:16;
                  Romans 2:24; 15:5-6; 2 Corinthians 6:14-7:1; Ephesians 1:4;
                  5:27; 1 Peter 2:12).
                </Typography>
              </li>
            </ul>
            <Typography variant="body1">
              In the process of church discipline, the members will pursue the
              sinning individual with compassion, love, kindness, respect, and
              dignity. Hateful and harassing behavior or attitudes directed
              toward any individual are to be repudiated and are not in accord
              with Scripture or the doctrines of the church. We teach that the
              faithful proclamation of the Scripture, including the call to
              repentance, does not constitute hate speech, or hateful and
              harassing behavior, but is instead a fundamental part of Christian
              accountability and the church&apos;s loving mission to the world.
            </Typography>
            <Typography variant="body1">
              The members of this church agree that there will be no appeal to
              any court of law because of dismissal or because of public
              statements to the congregation made in fulfillment to the
              disciplinary process. Members, who are under discipline by the
              church, as defined in the following sections, forfeit and waive
              the right to resign from the Church. The Church reserves the right
              to disclose disciplinary details to the leadership of church(es)
              attended by the member under discipline. Resignations from
              membership are possible only by members who are in good standing
              and who are not under disciplinary action.
            </Typography>
            <Typography variant="h3">
              6.1 Discipline against Private Sins (Matthew 18:15-20)
            </Typography>
            <Typography variant="body1">
              In Matthew 18:15-17, Jesus outlines the four step process by which
              the church is to seek the restoration of a believer who has fallen
              into a private sin. First, when a brother or sister sins, he or
              she is to be confronted privately by a single individual (v. 15).
              If he or she refuses to repent, the confronting individual is to
              take one or two other believers along to confront him or her again
              (v. 16). If the sinning brother refuses to listen to the two or
              three, the issue is to be brought before the church (v. 17). If
              there is still no repentance, the final step is excommunication
              (v. 17).
            </Typography>
            <Typography variant="body1">
              When church discipline escalates to the third step, the Elders
              will lead the process (cf. Gal. 6:1). After a thorough
              investigation, in accordance with the procedures prescribed by
              Scripture, if the Elders determine that there is sufficient
              evidence to conclude that an individual has sinned or is
              continuing to sin, that he or she has been appropriately
              confronted, and that he or she has refused to repent, then the
              Elders will inform the Church members at a members&apos; meeting
              so that the church may call the erring individual to repentance.
            </Typography>
            <ul>
              <li>
                <Typography variant="body1">
                  If the erring individual demonstrates repentance, then notice
                  to that effect may be given at a members&apos; meeting.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  If, however, the erring individual does not repent in response
                  to the church in its collective call to repentance, then he or
                  she will be publicly dismissed from the fellowship and/or
                  membership of the church at a regularly scheduled
                  members&apos; meeting.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  If the erring individual, after such dismissal, heeds the
                  warning, demonstrates repentance and requests reinstatement
                  before the Elders, then he or she will be publicly restored to
                  all the rights, duties, privileges, and responsibilities of
                  fellowship and/or membership.
                </Typography>
              </li>
            </ul>
            <Typography variant="h3">
              6.2 Discipline against Public Sins (1 Corinthians 5:9-13)
            </Typography>
            <Typography variant="body1">
              In 1 Corinthians 5:9-13, the Apostle Paul calls for members who
              are guilty of public sins to be excommunicated from the church.
              Since public sins are not hidden from the congregation, there is
              no need to proceed with the first two steps of church discipline
              as outlined in Matthew 18:15-20, which is intended to conceal the
              shame of sin from the public eye. For this reason, the Elders will
              immediately proceed with step 3 of church discipline as outlined
              under Section 6.1.
            </Typography>
            <Typography variant="h3">
              6.3 Discipline against Divisive Sins (Titus 3:10-11)
            </Typography>
            <Typography variant="body1">
              In Titus 3:10-11, the Apostle Paul calls for the excommunication
              of a member after two warnings if he or she is guilty of stirring
              up division in the church. Therefore, if the Elders determine a
              member to be dividing the Church, by but not limited to the
              undermining of the Church leadership, causing factions for
              political or social purposes, or misleading others into doctrinal
              error, they will give two warnings to the sinning individual to
              cease his or her divisive conduct. If the divisive member refuses
              to comply, the Elders will notify the congregation at a member’s
              meeting and excommunicate the sinning individual.
            </Typography>
            <Typography variant="h3">
              6.4 Discipline against the Sin of Laziness (2 Thessalonians
              3:6-15)
            </Typography>
            <Typography variant="body1">
              In 2 Thessalonians 3:6-15, the Apostle Paul calls for a lazy
              member to be disassociated from the body of believers so that he
              might realize laziness to be a serious sin and start looking for
              work. However, the sinning member is to still be considered a
              member of the Church. Therefore, if the Elders deem a member to be
              severely neglecting his or her responsibilities as a student or a
              laborer so that he or she might be financially burdening the
              members of his or her family or the Church, he or she will be
              prohibited from attending extracurricular Church activities. It
              will be up to the discretion of the Elders to notify individuals
              necessary to ensure that the lazy believer is abiding by this
              prohibition. The lazy member, however, may continue to attend
              Sunday worship services, regular weekly meetings, and scheduled
              member’s meetings.
            </Typography>
            <Typography variant="h3">
              6.5 Discipline against the Sin of Nonattendance (Hebrews 10:25)
            </Typography>
            <Typography variant="body1">
              According to Hebrews 10:24-25, members of the church are not to
              neglect the assembling of the saints. A professing Christian who
              is regularly absent especially for extended periods of time cannot
              fulfill their Christian duties, including worship, communion (1
              Corinthians 11:23-26), accountability (Matthew 18:15), submission
              to leadership (Hebrews 13:17), and interpersonal ministry within
              the church (1 Thessalonians 5:14). The willful and unrepentant
              neglect of such fundamental Christian responsibilities is a
              practical denial of one&apos;s profession of the Gospel for one
              who denies the members of the church body denies the head of the
              body, i.e. Jesus Christ (Matthew 25:41-46). Therefore, if a member
              has been absent from worship service for a period of six months
              and/or the Elders agree that the sinning individual is practically
              disengaged from the congregation, the Church will be notified of
              the extended absence and sinful neglect of the assembly, and the
              nonattending individual will be removed from the membership.
            </Typography>
          </section>
          <section id="2.7">
            <Typography variant="h2">
              Section 7 - Termination of Membership
            </Typography>
            <Typography variant="body1">
              The Church will recognize the termination of a person&apos;s
              membership following his or her death, or when he or she has
              voluntarily resigned or joined with another church. Membership may
              also be terminated as an act of church discipline as outlined in
              the previous sections. Any member with reason to believe that an
              individual should not be dismissed from the membership should
              express their concern to the Elders, upon which the termination
              will be reviewed. The Elders will have authority to refuse a
              member&apos;s voluntary resignation or transfer of membership to
              another church, either for the purpose of proceeding with a
              process of church discipline, or for any other biblical reasons.
            </Typography>
          </section>
        </article>
        <article id="meetings">
          <Typography variant="h1">
            <a href="#meetings">Article 3 - Meetings</a>
          </Typography>
          <section id="3.1">
            <Typography variant="h2">Section 1 - Worship Meetings</Typography>
            <Typography variant="body1">
              Worship services will be held on Sunday and may be held throughout
              the week as determined by the Church.
            </Typography>
          </section>
          <section id="3.2">
            <Typography variant="h2">
              Section 2 - Members&apos; Meetings
            </Typography>
            <Typography variant="body1">
              Every members&apos; meeting should be characterized by a spirit of
              mutual trust, openness, and loving consideration as is appropriate
              within the body of Christ.
            </Typography>
            <Typography variant="body1">
              There will be twelve members&apos; meetings each year. Each
              meeting will be held on the first Sunday of the month after our
              Lord&apos;s Day Worship Service, unless the majority of the Elders
              determine otherwise. Additional member&apos;s meetings may be
              instituted at the discretion of the majority of the Elders.
            </Typography>
            <Typography variant="body1">
              An elder designated by the Elders will preside as moderator at all
              members&apos; meetings of the Church. The Elders will see that the
              stated meetings of the Church are regularly held. At any
              members&apos; meeting, officers may be appointed and positions
              filled as needed, so long as all relevant constitutional
              requirements have been met.
            </Typography>
          </section>
        </article>
        <article id="elders">
          <Typography variant="h1">
            <a href="#elders">Article 4 - Elders</a>
          </Typography>
          <section>
            <Typography variant="h2">Section 1 - Elders</Typography>
            <Typography variant="body1">
              The term Pastor and Overseer are other biblical terms to describe
              the office of the Elder. The Elders shall be comprised of men who
              satisfy the qualifications for the office of Elder set forth in 1
              Timothy 3:1-7 and Titus 1:6-9. No elder shall hold the office of
              deacon during his tenure.
            </Typography>
            <Typography variant="h3">1.1 Qualifications</Typography>
            <Typography variant="body1">
              Each elder must be an active member of the church and possess the
              qualifications described in 1 Timothy 3:1-7 and Titus 1:6-9. He
              shall be:
            </Typography>
            <ul>
              <li>
                <Typography variant="body1">
                  Blameless as a steward of God; and above reproach (1 Timothy
                  3:2; Titus 1:6-7)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Husband of one wife; a one-woman man (1 Timothy 3:2; Titus
                  1:6)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Temperate, sober, vigilant (1 Timothy 3:2)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Sober-minded, prudent (1 Timothy 3:2; Titus 1:8)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Of good behavior; orderly, respectable (1 Timothy 3:2)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Given to hospitality (1 Timothy 3:2; Titus 1:8)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Able to teach, exhort believers, and refute false teaching (1
                  Timothy 3:2; Titus 1:9)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Not given to wine (1 Timothy 3:3; Titus 1:7)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Not violent; not argumentative (1 Timothy 3:3; Titus 1:7)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Patient, moderate, forbearing, gentle (1 Timothy 3:3)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Not a brawler, not contentious; not soon angry or
                  quick-tempered (1 Timothy 3:3; Titus 1:7)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Not covetous; not a lover of money; not greedy (1 Timothy 3:3;
                  Titus 1:7)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Rules well his own house; his children are faithful (1 Timothy
                  3:4; Titus 1:6)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Not a novice; not a new convert (1 Timothy 3:6)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Has a good reputation with outsiders (1 Timothy 3:7)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Not self-willed (Titus 1:7)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  A lover of good men and things (Titus 1:8)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">Just, fair (Titus 1:8)</Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Holy, devout (Titus 1:8)
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Self-controlled (Titus 1:8)
                </Typography>
              </li>
            </ul>
            <Typography variant="h3">
              1.2 Nomination and Ordination of Elders
            </Typography>
            <Typography variant="body1">
              The Elders by unanimous vote will nominate to the Church men
              gifted and willing to serve in the office of an elder as
              Elder-Candidates. Elder-Candidates will be observed by the
              congregation for two years so that the members might be able to
              assess the character of the nominee. Teaching opportunities,
              including preaching and small group leading, will also be provided
              for the church body to ensure that the Elder-Candidate is able to
              teach and is theologically sound. After 2 years, a congregational
              vote will be taken to assess the character and the abilities of
              the Elder-Candidate. The Elders will then take the congregational
              assessment into consideration before making the final decision to
              publicly ordain the Elder-Candidate into the position of an elder.
            </Typography>
            <Typography variant="h3">1.3 Removal of Elders</Typography>
            <Typography variant="body1">
              An elder&apos;s term of office may be terminated by resignation or
              by dismissal. An elder may be dismissed for any non-sin issue if
              75% of the Elders agree that the elder under scrutiny is not
              excellently fulfilling the qualifications listed above. An elder
              may also be dismissed on the charges of sin. In fulfillment of 1
              Timothy 5:17-21, any two members with reason to believe that an
              elder is guilty of a disqualifying sin should express such
              concerns to the Elders. If upon the assessment of the charges, the
              suspected elder is guilty, the Elders will confront the sinning
              elder and call him to repent. If he repents, he may still be
              dismissed from the office of an elder on the condition that 75% of
              the Elders agree that he no longer meets the qualification of
              Titus 1 and 1 Timothy 3. If the sinning elder refuses to repent,
              he will be dismissed from the office of an elder and the Elders
              will rebuke him before the entire congregation. If the sinning
              elder still refuses to repent, he will be excommunicated from the
              church.
            </Typography>
            <Typography variant="h3">
              1.4 Responsibility and Powers of the Elders
            </Typography>
            <Typography variant="body1">
              The Elders shall oversee the ministry and resources of the church.
              In keeping with the principles set forth in Acts 6:1-6 and 1 Peter
              5:1-4, the Elders shall devote their time to prayer, the ministry
              of the Word (by teaching and encouraging sound doctrine), and
              shepherding God&apos;s flock.
            </Typography>
            <Typography variant="body1">
              The Elders shall take particular responsibility to examine and
              recommend all prospective candidates for offices and positions,
              oversee the work of the Deacons and appointed church officers and
              committees, conduct worship services, administer the ordinances of
              baptism and communion, equip the membership for the work of the
              ministry, encourage sound doctrine and practice, admonish and
              correct error, oversee the process of church discipline,
              coordinate and promote the ministries of the church, and mobilize
              the church for world missions.
            </Typography>
            <Typography variant="body1">
              The Elders may establish ministry positions or committees to
              assist them in fulfilling their responsibilities and may also
              determine funds be utilized for new paid staff positions. The
              Elders shall have primary responsibility for the employment,
              supervision, and evaluation of all paid staff members.
            </Typography>
            <Typography variant="body1">
              The Elders have the power to make such disbursements from the
              funds and properties of the Church as are required to fulfill the
              purposes of the Church and generally to conduct, manage, and
              control the activities and affairs of the Church and to make such
              rules and regulations consistent with the Scriptures, with law,
              with the Articles of Incorporation, the Bylaws, or with this
              Constitution, as they may deem best.
            </Typography>
            <Typography variant="body1">
              Each year the Elders shall present to the church an itemized
              budget. This budget shall be presented for discussion at our
              regularly scheduled members&apos; meeting. No money shall be
              solicited by or on behalf of the Church or any of its ministries
              without the approval of the Elders.
            </Typography>
            <Typography variant="h3">1.5 Staff-Elder</Typography>
            <Typography variant="body1">
              If a member fulfills the qualifications for eldership and excels
              in the work of the ministry, he may be employed for the Church as
              a Staff-Elder to fulfill the responsibilities of an elder as his
              full-time work. Although he will do more work in the Church, he
              will not have any more authority than non-employed elders.
            </Typography>
          </section>
        </article>
        <article id="officers">
          <Typography variant="h1">
            <a href="#officers">Article 5 - Other Officers and Committees</a>
          </Typography>
          <section id="5.1">
            <Typography variant="h2">Section 1 - Summary</Typography>
            <Typography variant="body1">
              In addition to the office of the Elder, the Bible establishes the
              office of the Deacon. Under this constitution our church also
              recognizes the administrative position of a treasurer. All
              officers must be members of this church prior to assuming their
              responsibilities.
            </Typography>
          </section>
          <section id="5.2">
            <Typography variant="h2">Section 2 - Deacon</Typography>
            <Typography variant="body1">
              A deacon must be a member possessing the qualifications described
              in Acts 6:1-7 and 1 Timothy 3:8-13. He must be an individual who
              is particularly gifted in ministering to the needs of the members
              of the church and encouraging the saints, working under the
              guidance and the directions of the Elders.
            </Typography>
            <Typography variant="body1">
              Deacons will be nominated by the Elders and will be observed by
              the Church body for one year in order to ensure that the nominee
              fulfills the appropriate qualifications. If the nominee is
              confirmed by 90% of the members, the Elders will appoint him to
              the office of a deacon.
            </Typography>
            <Typography variant="body1">
              If the 75% of the Elders agree that a deacon is not excellently
              fulfilling the qualifications of Acts 6 and 1 Timothy 3, he will
              be dismissed from the office. If a deacon is guilty of a
              disqualifying sin, he will be removed from his office and undergo
              the appropriate disciplinary process of Article 2, Section 6.
            </Typography>
          </section>
          <section id="5.3">
            <Typography variant="h2">Section 3 - Treasurer</Typography>
            <Typography variant="body1">
              The treasurer will not be a paid Church staff member. He will
              ensure that all funds and securities of the church are properly
              secured in such banks, financial institutions, or depositories as
              appropriate. The treasurer will also be responsible for presenting
              regular reports of the account balances, revenues and expenses of
              the Church at each members&apos; meeting. The treasurer will also
              ensure that full and accurate accounts of receipts and
              disbursements are kept in books belonging to the church, and that
              adequate controls are implemented to guarantee that all funds
              belonging to the church are appropriately handled by any officer,
              employee, or agent of the church. The treasurer will render to the
              Elders annually, or whenever they may require it, an account of
              all transactions as treasurer and of the financial condition of
              the church. The treasurer will be selected and dismissed by the
              Elders.
            </Typography>
          </section>
          <section id="5.4">
            <Typography variant="h2">
              Section 4 - Councils and Committees
            </Typography>
            <Typography variant="body1">
              To promote efficient handling of church matters, the Elders may
              appoint various councils and committees from within its
              membership, the staff, and from the church at large. These
              councils and committees shall perform tasks solely in accordance
              with the duties and with powers specifically delegated by the
              Elders. The general functions of councils and committees are: All
              councils and committees shall exist for the period specified by
              the Elders.
            </Typography>
            <ul>
              <li>
                <Typography variant="body1">
                  To bring considered recommendations to the Elders concerning
                  ministries.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  To provide a wider base of counsel to the Elders having the
                  oversight of specific ministries.
                </Typography>
              </li>
            </ul>
          </section>
        </article>
        <article id="ordination">
          <Typography variant="h1">
            <a href="#ordination">
              Article 6 - Ordination, Licensing, and Commissioning
            </a>
          </Typography>
          <section id="6.1">
            <Typography variant="h2">Section 1 - Ordination</Typography>
            <Typography variant="body1">
              Ordination refers to the unanimous recognition by the Elders of a
              man&apos;s qualification to the ministry and preparation as a
              shepherd. Ordination shall be conferred for life, so long as the
              man continues to manifest the qualifications of the office.
              Details for the ordination and removal of an elder is detailed in
              Article 4, Section 1.2-3.
            </Typography>
          </section>
          <section id="6.2">
            <Typography variant="h2">Section 2 - Licensing</Typography>
            <Typography variant="body1">
              The license is issued by the Elders and is given in recognition of
              a man&apos;s service in a ministry. Its aim is to allow a man to
              perform the ecclesiastical duties and functions of the church.
              Licenses will be evaluated — and issued or renewed — each year at
              the sole discretion of the Elders. Licenses are to be issued only
              to church members of CrossLife Bible Church.
            </Typography>
          </section>
          <section id="6.3">
            <Typography variant="h2">Section 3 - Commissioning</Typography>
            <Typography variant="body1">
              When local-church certification is required for ministry or where
              ordination would otherwise be unnecessary or inappropriate, a
              person may be commissioned by the Elders to minister. This
              commissioning continues as long as the opportunity to minister
              remains in effect and as long as the person maintains the
              qualifications for ministry, or until the Elders in its sole
              discretion revokes the commissioning.
            </Typography>
          </section>
          <section id="6.4">
            <Typography variant="h2">
              Section 4 - Marriages and Weddings
            </Typography>
            <Typography variant="body1">
              All who are ordained, licensed, commissioned or otherwise
              authorized by this Church to solemnize marriages may do so, in the
              exercise of religious freedom, only where the participants are one
              man (with XY chromosomes) and one woman (with XX chromosomes)
              (Genesis 2:21-24; Matthew 19:4-6; Mark 10:6-9). If the Church
              possesses facilities, the facilities may be used for weddings only
              if at least one of the prospective spouses is a Church member in
              good standing subject to the review of the Elders; church
              facilities are not available to the general public for that
              purpose.
            </Typography>
          </section>
        </article>
        <article id="disputes">
          <Typography variant="h1">
            <a href="#disputes">Article 7 - Disputes Resolution</a>
          </Typography>
          <Typography variant="body1">
            The Bible commands Christians to make every effort to live at peace
            and to resolve disputes with each other in private or within the
            Christian Church (Matthew 18:15-20, 1 Corinthians 6:1-8). The
            Church, therefore, shall require its members to resolve personal
            conflicts among themselves according to biblically based principles,
            without reliance upon any secular court of law. Consistent with its
            call to peacemaking, the Church shall encourage the use of
            biblically based principles to resolve disputes between itself and
            those outside the Church, whether Christian or pagan and whether
            individuals or corporate entities.
          </Typography>
          <Typography variant="body1">
            If a dispute arises between Church members, pastors, or staff
            involving any matter pertaining to spiritual teaching or practices,
            Church finances, or title to property purchased with Church
            contributions, the dispute shall be resolved by the Elders of the
            Church. A decision shall be reached after prayerful consideration,
            in a spirit of humility, with each Elder regarding one another
            before himself and striving to preserve the unity of the Spirit in
            the bond of peace (Ephesians 4:1-3).
          </Typography>
          <Typography variant="body1">
            If a conflict or dispute regards a sin that is criminal in nature,
            then the police will be notified so that the crime might be dealt
            with in accordance with the laws of the land (Romans 13:1-7), while
            the Church deals with the sin in accordance to the Scriptures.
          </Typography>
        </article>
        <article id="amendments">
          <Typography variant="h1">
            <a href="#amendments">Article 8 - Amendments</a>
          </Typography>
          <Typography variant="body1">
            The Statement of Faith and Church Covenant may be amended by a 75%
            vote of the Elders. The Church members must be notified of the
            changes at the next scheduled members&apos; meeting.
          </Typography>
        </article>
      </div>
    </ConstitutionContainer>
  )
}

export default ConstitutionSection
