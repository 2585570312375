import { Button, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import customTheme from '../theme'
import FeatureCarousel from './FeatureCarousel'
import {
  ABOUT_US_DESCRIPTION,
  ABOUT_US_TITLE,
  WELCOME_BUTTON_TEXT,
  WELCOME_SUBTITLE,
  WELCOME_TITLE,
} from './strings'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const WelcomeSection = styled.section`
  max-width: 550px;
  margin: 25px auto;
  text-align: center;

  ${customTheme.breakpoints.down('sm')} {
    margin: 50px 20px;
  }
`

const CarouselSection = styled.section`
  background-color: #e8ede2;
`

const AboutUsSection = styled.section`
  display: flex;
  justify-content: center;
  padding: 50px 5%;

  ${customTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`

const ChurchBuildingImage = styled.img`
  max-width: 475px;
  width: 35%;

  ${customTheme.breakpoints.down('sm')} {
    width: 100%;
    margin-bottom: 30px;
  }
`

const AboutUsDescription = styled(Stack)`
  margin-left: 50px;
  width: 50%;

  ${customTheme.breakpoints.down('sm')} {
    margin-left: 0;
    width: 100%;
  }
`

const Home: FC = () => {
  return (
    <Container>
      <WelcomeSection>
        <Typography variant="h1" gutterBottom>
          {WELCOME_TITLE}
        </Typography>
        <Typography variant="h4" sx={{ mb: '20px' }}>
          {WELCOME_SUBTITLE}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="new/welcome"
        >
          {WELCOME_BUTTON_TEXT}
        </Button>
      </WelcomeSection>
      <CarouselSection>
        <FeatureCarousel />
      </CarouselSection>
      <AboutUsSection>
        <ChurchBuildingImage
          src="https://assets.crosslifebiblechurch.org/church-building.jpg"
          alt="church-building"
        />
        <AboutUsDescription>
          <Typography variant="h2" gutterBottom>
            {ABOUT_US_TITLE}
          </Typography>
          <Typography variant="body1">{ABOUT_US_DESCRIPTION}</Typography>
        </AboutUsDescription>
      </AboutUsSection>
    </Container>
  )
}

export default Home
