import { Alert, Box, Typography } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import { PlanningMailToLink } from '../common/MailToLink'
import {
  RIDES_DESCRIPTION,
  PICK_UP_TIMES_TITLE,
  PICK_UP_LOCATIONS_AND_TIMES_LIST,
  RETURNING_RIDES_TITLE,
  RETURNING_RIDES_AND_TIMES_LIST,
} from './strings'

const StyledImage = styled.img`
  width: 100%;
  margin-top: 25px;
`

const RidesSection: FC = () => {
  return (
    <>
      <Alert severity="info" sx={{ marginBottom: '25px' }}>
        {RIDES_DESCRIPTION} <PlanningMailToLink />.
      </Alert>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" align="center">
          {PICK_UP_TIMES_TITLE}
        </Typography>
        {PICK_UP_LOCATIONS_AND_TIMES_LIST.map((ride) => {
          return (
            <Typography align="center" gutterBottom>
              {ride.location} | {ride.time}
            </Typography>
          )
        })}
        <Typography variant="h6" align="center" sx={{ marginTop: '25px' }}>
          {RETURNING_RIDES_TITLE}
        </Typography>
        {RETURNING_RIDES_AND_TIMES_LIST.map((ride) => {
          return (
            <Typography align="center" gutterBottom>
              {ride.location} | {ride.time}
            </Typography>
          )
        })}
        <StyledImage
          src="https://assets.crosslifebiblechurch.org/college-rides-map.png"
          alt="College Rides Map"
        />
      </Box>
    </>
  )
}

export default RidesSection
