import { Stack } from '@mui/material'
import { FC, useEffect } from 'react'
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Footer from './footer'
import NavBar from './nav'

const FlexibleContainer = styled.div`
  flex-grow: 1;
`

const App: FC = () => {
  const location = useLocation()

  // apply linking of references to page everytime route changes
  useEffect(() => {
    window.dispatchEvent(new Event('esv-crossref.trigger-linkify'))
  }, [location])

  return (
    <Stack direction="column" sx={{ height: '100vh' }}>
      <NavBar />
      <FlexibleContainer>
        <Outlet />
      </FlexibleContainer>
      <Footer />
      <ScrollRestoration />
    </Stack>
  )
}

export default App
