import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material'
import React, { FC, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import MenuIcon from '@mui/icons-material/Menu'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import customTheme from '../theme'
import { NAVIGATION_TAB_OPTIONS } from './tab-types'

const NavBarContainer = styled(Stack)`
  height: 100px;
  padding: 0 1% 0 0;

  ${customTheme.breakpoints.down('sm')} {
    height: 75px;
    padding: 0 3% 0 0;
  }
`

const Brand = styled.img`
  width: 225px;

  ${customTheme.breakpoints.down('sm')} {
    width: 175px;
  }
`

const StyledDrawer = styled(SwipeableDrawer)`
  a:first-child {
    border-top: 0;
  }
`

const StyledAccordion = styled(Accordion)`
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;

  &:not(:last-child) {
    border-top: 0;
  }

  &:before {
    display: none;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;

  &:visited,
  &:active {
    color: black;
  }
`

const NavigationLink = styled(StyledLink)`
  padding: 12px 16px;
  border-bottom: 1px solid #d3d3d3;
`

const MobileMenu: FC = () => {
  const currentUrl = useLocation()

  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedAccordion, setSelectedAccordion] = useState<string>()
  const [initialExpandedAccordion, setInitialExpandedAccordion] =
    useState<boolean>(true)

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer)
  }

  const handleAccordionChange =
    (accordion: string) => (event: React.SyntheticEvent, expanded: boolean) => {
      setInitialExpandedAccordion(false)
      setSelectedAccordion(expanded ? accordion : '')
    }

  return (
    <NavBarContainer
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p="0 1%"
      height="100px"
    >
      <Link to="/">
        <Brand
          src="https://assets.crosslifebiblechurch.org/crosslife-logo.svg"
          alt="crosslife-logo"
        />
      </Link>
      <IconButton onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
      <StyledDrawer
        anchor="top"
        open={showDrawer}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
      >
        {NAVIGATION_TAB_OPTIONS.map((option) => {
          if (option.subOptions) {
            const shouldExpand = option.subOptions!!.some((subOption) =>
              currentUrl.pathname.includes(subOption.href!!)
            )

            return (
              <StyledAccordion
                key={option.label}
                expanded={
                  selectedAccordion === option.label ||
                  (initialExpandedAccordion && shouldExpand)
                }
                onChange={handleAccordionChange(option.label)}
                disableGutters
                elevation={0}
                square
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h3">{option.label}</Typography>
                </AccordionSummary>
                {option.subOptions.map((subOption) => {
                  const matchCurrentPath =
                    currentUrl.pathname === subOption.href
                  return (
                    <AccordionDetails key={subOption.label}>
                      <StyledLink to={subOption.href!!} onClick={toggleDrawer}>
                        <Typography
                          variant="h4"
                          color={matchCurrentPath ? 'primary' : 'text.primary'}
                        >
                          {subOption.label}
                        </Typography>
                      </StyledLink>
                    </AccordionDetails>
                  )
                })}
              </StyledAccordion>
            )
          }

          return (
            <NavigationLink
              key={option.label}
              to={option.href!!}
              onClick={toggleDrawer}
            >
              <Typography
                variant="h3"
                color={
                  currentUrl.pathname === option.href!!
                    ? 'primary'
                    : 'text.primary'
                }
              >
                {option.label}
              </Typography>
            </NavigationLink>
          )
        })}
      </StyledDrawer>
    </NavBarContainer>
  )
}

export default MobileMenu
