import { useMediaQuery } from '@mui/material'
import { Outlet } from 'react-router-dom'
import ResponsiveContainer from '../common/ResponsiveContainer'
import SecondaryNavigationTabs from '../common/SecondaryNavigationTabs'
import SectionHeader from '../common/SectionHeader'
import { NAVIGATION_TAB_OPTIONS } from '../nav/tab-types'
import customTheme from '../theme'

const Lesson = () => {
  const matches = useMediaQuery(customTheme.breakpoints.down('sm'))

  const navigationSubOptions = NAVIGATION_TAB_OPTIONS.find(
    (tab) => tab.label === 'Classes'
  )

  return (
    <>
      {!matches && (
        <SecondaryNavigationTabs
          subOptions={navigationSubOptions?.subOptions || []}
        />
      )}
      <SectionHeader subOptions={navigationSubOptions?.subOptions} />
      <ResponsiveContainer>
        <Outlet />
      </ResponsiveContainer>
    </>
  )
}

export default Lesson
