export type TabOptionProps = {
  /**
   * Url path for this tab
   */
  href?: string

  /**
   * The label of this tab
   */
  label: string

  /**
   * The options for this tab
   */
  subOptions?: SubOptionProps[]
}

export type SubOptionProps = {
  /**
   * The url of this sub option
   */
  href: string

  /**
   * The label of this sub option
   */
  label: string
}

export const NAVIGATION_TAB_OPTIONS: TabOptionProps[] = [
  {
    href: '/',
    label: 'Home',
  },
  {
    label: 'New Here',
    subOptions: [
      {
        href: '/new/welcome',
        label: 'Welcome',
      },
      {
        href: '/new/gospel',
        label: 'The Gospel (The Good News)',
      },
      {
        href: '/new/time-and-location',
        label: 'Time and Location',
      },
      {
        href: '/new/rides',
        label: 'Rides',
      },
      {
        href: '/new/faqs',
        label: 'FAQs',
      },
      {
        href: '/new/leadership',
        label: 'Leadership',
      },
      {
        href: '/new/constitution',
        label: 'Constitution',
      },
      {
        href: '/new/doctrinal-statement',
        label: 'Doctrinal Statement',
      },
      {
        href: '/new/membership-covenant',
        label: 'Membership Covenant',
      },
    ],
  },
  {
    label: 'Ministries',
    subOptions: [
      {
        href: '/ministry/partner',
        label: 'Ministry Partners',
      },
      {
        href: '/ministry/men',
        label: "Men's Ministry",
      },
      {
        href: '/ministry/women',
        label: "Women's Ministry",
      },
      {
        href: '/ministry/college',
        label: 'College Ministry',
      },
      {
        href: '/ministry/children',
        label: "Children's Ministry",
      },
      {
        href: '/ministry/community-groups',
        label: 'Community Groups',
      },
      {
        href: '/ministry/adult-small-groups',
        label: 'Adult Small Groups',
      },
    ],
  },
  {
    label: 'Classes',
    subOptions: [
      {
        href: '/class/church-membership',
        label: 'Church Membership',
      },
      {
        href: '/class/fundamentals-of-the-faith',
        label: 'Fundamentals of the Faith',
      },
      {
        href: '/class/evangelism-training',
        label: 'Evangelism Training',
      },
    ],
  },
  {
    href: '/sermon',
    label: 'Sermons',
  },
  {
    href: '/giving',
    label: 'Giving',
  },
]
