import { useMediaQuery } from '@mui/material'
import { FC } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import customTheme from '../theme'

const CarouslImage = styled.img`
  width: auto;
`

const Container = styled.div`
  width: 63%;
  margin: 0 auto;

  ${customTheme.breakpoints.down('sm')} {
    width: 100%;
  }

  a {
    display: block;
  }
`

type SlideProps = {
  desktopUrl: string
  mobileUrl: string
  description: string
  linkTo?: string
}

const LIST_OF_SLIDES: SlideProps[] = [
  {
    desktopUrl: 'https://assets.crosslifebiblechurch.org/Banner-Welcome.png',
    mobileUrl:
      'https://assets.crosslifebiblechurch.org/Banner-Welcome-Mobile.png',
    description: 'Service time',
  },
  {
    desktopUrl: 'https://assets.crosslifebiblechurch.org/Banner-Rides.png',
    mobileUrl:
      'https://assets.crosslifebiblechurch.org/Banner-Rides-Mobile.png',
    description: 'College rides time',
    linkTo: 'new/rides',
  },
]

const FeatureCarousel: FC = () => {
  const matches = useMediaQuery(customTheme.breakpoints.down('sm'))

  return (
    <Container>
      <Carousel
        autoPlay
        showThumbs={false}
        showStatus={false}
        interval={10000}
        infiniteLoop
      >
        {LIST_OF_SLIDES.map((slide) => (
          <Link to={slide.linkTo || 'new/time-and-location'} key={slide.linkTo}>
            <CarouslImage
              src={matches ? slide.mobileUrl : slide.desktopUrl}
              alt={slide.description}
            />
          </Link>
        ))}
      </Carousel>
    </Container>
  )
}

export default FeatureCarousel
