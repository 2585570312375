import { Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

type ParagraphProps = {
  className?: string
  children: ReactNode
}

const Paragraph: FC<ParagraphProps> = ({ className, children }) => {
  return (
    <Typography
      variant="body1"
      sx={{ marginBottom: '30px' }}
      className={className}
    >
      {children}
    </Typography>
  )
}

export default Paragraph
