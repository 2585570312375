import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Paragraph from '../common/Paragraph'
import {
  CLASS_INFO_LABEL,
  CLASS_OUTLINE_LABEL,
  FOF_DESCRIPTION_1,
  FOF_DESCRIPTION_2,
  FOF_DESCRIPTION_3,
  FOF_DURATION_CONTENT,
  FOF_LOCATION_CONTENT,
  FOF_UPCOMING_DATES_CONTENT,
  FOF_WEEK_CONTENT,
  WEEK,
} from './strings'
import { PlanningMailToLink } from '../common/MailToLink'
import DetailsSection from './DetailsSection'

function createData(week: string, topic: string) {
  return { week, topic }
}

const rows = FOF_WEEK_CONTENT.map((content, index) =>
  createData(`${WEEK} ${index}`, content)
)

const FundamentalsOfTheFaith = () => {
  return (
    <>
      <Paragraph>{FOF_DESCRIPTION_1}</Paragraph>
      <Paragraph>{FOF_DESCRIPTION_2}</Paragraph>
      <Paragraph>{FOF_DESCRIPTION_3}</Paragraph>
      <Typography variant="h2" gutterBottom>
        {CLASS_INFO_LABEL}
      </Typography>
      <DetailsSection
        upcomingDates={FOF_UPCOMING_DATES_CONTENT}
        duration={FOF_DURATION_CONTENT}
        location={FOF_LOCATION_CONTENT}
        email={<PlanningMailToLink />}
      />
      <Typography variant="h2" gutterBottom>
        {CLASS_OUTLINE_LABEL}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableCell>Week</TableCell>
            <TableCell>Topic</TableCell>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  key={row.week}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.week}
                  </TableCell>
                  <TableCell align="left">{row.topic}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default FundamentalsOfTheFaith
