import { IconButton, Typography } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import customTheme from '../../theme'
import {
  BIOGRAPHY_TITLE,
  EDUCATION_TITLE,
  FAVORITE_VERSE_TITLE,
  HOBBIES_TITLE,
} from '../strings'
import LEADERSHIP_DATA from './leadership-data'

const LeadershipProfilePicture = styled.img`
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;

  // Center image
  display: block;
  margin: 0 auto 10px;

  ${customTheme.breakpoints.down('sm')} {
    width: 300px;
    height: 300px;
  }
`

const LeadershipPastorContainer = styled.div`
  margin-top: 3rem;

  > h6 {
    margin-bottom: 5px;
  }

  > p {
    margin-bottom: 30px;
  }

  ul {
    list-style: circle;
    margin-bottom: 30px;

    li {
      margin: 0 0 5px 40px;
    }
  }
`

const LeadershipInformation: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const leadershipData = LEADERSHIP_DATA.find((data) => data.id === id)

  if (leadershipData === undefined) {
    throw new Error('Not found')
  }

  return (
    <div>
      <IconButton onClick={() => navigate('/new/leadership')}>
        <ArrowBack />
      </IconButton>
      <LeadershipPastorContainer>
        <LeadershipProfilePicture src={leadershipData.image} alt="profile" />
        <Typography align="center" variant="h3">
          {leadershipData?.name}
        </Typography>
        <Typography align="center" variant="h6">
          {leadershipData?.role}
        </Typography>
        <Typography variant="h6">{BIOGRAPHY_TITLE}</Typography>
        {leadershipData?.biography.map((biography) => (
          <Typography variant="body1">{biography}</Typography>
        ))}
        <Typography variant="h6">{EDUCATION_TITLE}</Typography>
        <ul>
          {leadershipData?.education.map((education) => (
            <li>
              <Typography variant="body1">{education}</Typography>
            </li>
          ))}
        </ul>
        <Typography variant="h6">{FAVORITE_VERSE_TITLE}</Typography>
        <Typography variant="body1">{leadershipData?.favoriteVerse}</Typography>
        <Typography variant="h6">{HOBBIES_TITLE}</Typography>
        <ul>
          {leadershipData?.hobbies.map((hobby) => (
            <li>
              <Typography variant="body1">{hobby}</Typography>
            </li>
          ))}
        </ul>
      </LeadershipPastorContainer>
    </div>
  )
}

export default LeadershipInformation
