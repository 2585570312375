import { FC } from 'react'
import { MenuItem, TextField } from '@mui/material'
import { SEARCH_BY_LABEL } from '../strings'
import SearchType from './SearchType'

const filterTypes = [SearchType.Title, SearchType.Series]

type SermonSearchFilterProps = {
  onSermonFilterChange: (filterType: string) => void

  className?: string
}

const SermonSearchFilter: FC<SermonSearchFilterProps> = ({
  onSermonFilterChange,
  className,
}) => {
  return (
    <TextField
      className={className}
      sx={{ width: '100%' }}
      select
      label={SEARCH_BY_LABEL}
      defaultValue={SearchType.Title}
      onChange={(event) => {
        onSermonFilterChange(event.target.value)
      }}
    >
      {filterTypes.map((type) => (
        <MenuItem key={type} value={type}>
          {type}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SermonSearchFilter
