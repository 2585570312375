import { Stack } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import DesktopNavigationTabs from './DesktopNavigationTabs'

const NavBarContainer = styled(Stack)`
  height: 100px;
  padding: 0 1% 0 0;
`

const Brand = styled.img`
  width: 225px;
`

const DesktopMenu: FC = () => (
  <NavBarContainer
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    p="0 1%"
    height="100px"
  >
    <Link to="/">
      <Brand
        src="https://assets.crosslifebiblechurch.org/crosslife-logo.svg"
        alt="crosslife-logo"
      />
    </Link>
    <Stack direction="row" alignItems="center">
      <DesktopNavigationTabs />
    </Stack>
  </NavBarContainer>
)

export default DesktopMenu
