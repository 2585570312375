/* eslint-disable no-console */
import axios, { AxiosResponse } from 'axios'
import { GetSermonSeriesListResponse } from './types/sermon-series'
import { GetSermonListResponse } from './types/sermon'

const sermonClient = axios.create({
  baseURL: 'https://api.crosslifebiblechurch.org',
  timeout: 5000,
})

export type GetSermonListRequest = {
  tagId?: string
  match?: string
  nextToken?: string
}

export const callGetSermonList = async ({
  nextToken,
  tagId,
  match,
}: GetSermonListRequest): Promise<AxiosResponse<GetSermonListResponse>> => {
  try {
    return await sermonClient.get<GetSermonListResponse>('/sermons', {
      params: {
        nextToken,
        tagId,
        match: match?.toLowerCase(),
      },
    })
  } catch (ex) {
    console.error('Error ocurred while calling sermon', ex)
    throw ex
  }
}

/**
 * Calls the API to retrieve a list of sermon series.
 * @returns A list of sermon series.
 */
export const callGetSermonSeriesList = async (): Promise<
  AxiosResponse<GetSermonSeriesListResponse>
> => {
  try {
    return await sermonClient.get<GetSermonSeriesListResponse>('/sermon-series')
  } catch (ex) {
    console.error('Error', ex)
    throw ex
  }
}
