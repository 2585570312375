import { Container } from '@mui/material'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import customTheme from '../theme'

const StyledContainer = styled(Container)`
  padding: 4% 6% 0;

  ${customTheme.breakpoints.down('sm')} {
    padding: 20px 20px;
  }
`

type ResponsiveContainerProps = {
  children: ReactNode
}

const ResponsiveContainer: FC<ResponsiveContainerProps> = ({ children }) => {
  return <StyledContainer maxWidth="md">{children}</StyledContainer>
}

export default ResponsiveContainer
