import { Menu, MenuItem, Tab } from '@mui/material'
import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { SubOptionProps, TabOptionProps } from './tab-types'

const DesktopNavigationTab: FC<TabOptionProps> = ({
  href,
  label,
  subOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleclick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (href) {
    return (
      <Tab component={Link} to={href} label={label} onClick={handleclick} />
    )
  }

  return (
    <>
      <Tab label={label} onClick={handleclick} />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {subOptions?.map((subOption: SubOptionProps) => (
          <MenuItem
            component={Link}
            to={subOption.href}
            onClick={handleClose}
            key={subOption.label}
          >
            {subOption.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default DesktopNavigationTab
