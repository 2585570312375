import { Paper, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { FC } from 'react'
import styled from 'styled-components'
import { Sermon } from '../client/types/sermon'
import SermonActions from './SermonItemActions'
import 'react-h5-audio-player/lib/styles.css'
import customTheme from '../theme'
import {
  MISSING_VERSE,
  PREACHER_LABEL,
  SCRIPTURE_LABEL,
  SERIES_LABEL,
} from './strings'

const StyledPaper = styled(Paper)`
  display: flex;

  margin-bottom: 30px;
  padding: 21px 29px;

  border-radius: 5px;
`

const SermonInfo = styled.div`
  flex-grow: 1;

  min-width: 0;
  padding: 0 10% 0 0;
  flex-direction: column;

  ${customTheme.breakpoints.down('sm')} {
    padding: 0 5% 0 0;
  }
`

const SermonDate = styled(Typography)`
  margin-bottom: 10px;
  ${customTheme.breakpoints.down('sm')} {
    font-size: 0.85rem;
  }
`

const SermonDetails = styled.div`
  display: flex;
  font-size: 1.15rem;

  > :not(:last-child) {
    margin-right: 30px;
  }

  ${customTheme.breakpoints.down('md')} {
    flex-direction: column;

    font-size: 0.85rem;
  }
`

const SermonDetailGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const SermonTitle = styled(Typography)`
  font-weight: 700;
  margin-bottom: 18px;

  ${customTheme.breakpoints.down('sm')} {
    font-size: 1.3rem;
  }
`

type SermonItemProps = {
  sermon: Sermon
  sermonSeriesTitle: string
  selectedSermon?: Sermon | undefined
  handleOnClick: (sermon?: Sermon) => void
}

const SermonItem: FC<SermonItemProps> = ({
  sermon,
  sermonSeriesTitle,
  selectedSermon,
  handleOnClick,
}) => {
  return (
    <StyledPaper elevation={0} variant="secondary">
      <SermonInfo>
        <SermonDate variant="body1">
          {format(parseISO(sermon.date), 'PPP')}
        </SermonDate>
        <SermonTitle variant="h3">{sermon.title}</SermonTitle>
        <SermonDetails>
          <SermonDetailGroup>
            <Typography variant="body1" color="secondary.light">
              {SCRIPTURE_LABEL}
            </Typography>
            <Typography variant="body2" color="primary.dark">
              {sermon.verse || MISSING_VERSE}
            </Typography>
          </SermonDetailGroup>
          <SermonDetailGroup>
            <Typography variant="body1" color="secondary.light">
              {SERIES_LABEL}
            </Typography>
            <Typography variant="body2" color="primary.dark">
              {sermonSeriesTitle}
            </Typography>
          </SermonDetailGroup>
          <SermonDetailGroup>
            <Typography variant="body1" color="secondary.light">
              {PREACHER_LABEL}
            </Typography>
            <Typography variant="body2" color="primary.dark">
              {sermon.speakerName}
            </Typography>
          </SermonDetailGroup>
        </SermonDetails>
      </SermonInfo>
      <SermonActions
        sermon={sermon}
        selectedSermon={selectedSermon}
        handleOnClick={handleOnClick}
      />
    </StyledPaper>
  )
}

export default SermonItem
