import { CardContent, Typography } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import InfoCard from '../common/InfoCard'
import {
  COVENANT_COMMITMENT_LIST,
  COVENANT_DESCRIPTION_1,
  COVENANT_DESCRIPTION_2,
  COVENANT_TITLE,
  PREAMBLE_DESCRIPTION_1,
  PREAMBLE_DESCRIPTION_2,
  PREAMBLE_TITLE,
} from './strings'

const MembershipCovenantContainer = styled.div`
  ul {
    margin-top: 10px;
    list-style: circle;

    li {
      margin: 0 0 10px 30px;
    }
  }
`

const MembershipCovenantSection: FC = () => {
  return (
    <MembershipCovenantContainer>
      <InfoCard>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {PREAMBLE_TITLE}
          </Typography>
          <Typography marginBottom="30px">{PREAMBLE_DESCRIPTION_1}</Typography>
          <Typography>{PREAMBLE_DESCRIPTION_2}</Typography>
        </CardContent>
      </InfoCard>
      <InfoCard>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {COVENANT_TITLE}
          </Typography>
          <Typography marginBottom="30px">{COVENANT_DESCRIPTION_1}</Typography>
          <ul>
            {COVENANT_COMMITMENT_LIST.map((commitment) => (
              <li>
                <Typography marginBottom="30px">{commitment}</Typography>
              </li>
            ))}
          </ul>
          <Typography>{COVENANT_DESCRIPTION_2}</Typography>
        </CardContent>
      </InfoCard>
    </MembershipCovenantContainer>
  )
}

export default MembershipCovenantSection
