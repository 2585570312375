import { FC } from 'react'
import { PlanningMailToLink } from '../common/MailToLink'
import Paragraph from '../common/Paragraph'

const WomensMinistry: FC = () => {
  return (
    <>
      <Paragraph>
        Women&#39;s Ministry meets monthly at church, typically on the last
        Saturday morning of each month. Meetings are open to all ages and
        visitors and begin with a time of praise, then a time of discussion and
        small groups covering a different topic each month.
      </Paragraph>
      <Paragraph>
        Currently, the topic is biographies of notable Christian women.
        Women&#39;s Ministry also hosts our Women&#39;s Weekender retreat every
        two years in the Fall. Contact <PlanningMailToLink /> for more details.
      </Paragraph>
    </>
  )
}

export default WomensMinistry
