import { CircularProgress, Typography } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Sermon } from '../client/types/sermon'
import 'react-h5-audio-player/lib/styles.css'
import { callGetSermonList } from '../client/sermon-client'
import SermonItem from './SermonItem'
import SermonSeries from '../client/types/sermon-series'
import SermonAudioPlayer from './SermonAudioPlayer'
import { NO_SERMONS_FOUND } from './strings'

type SermonListProps = {
  /**
   * The sermon series that is populating the sermon list.
   */
  searchParams?: URLSearchParams

  /**
   * A list of sermon series
   */
  idToSermonSeriesMap: { [key: string]: SermonSeries }
}

const SermonList: FC<SermonListProps> = ({
  searchParams,
  idToSermonSeriesMap,
}) => {
  // States to track sermon list and the API call associated with the sermon list.
  const [sermonList, setSermonList] = useState<Sermon[]>([])
  const [nextPageToken, setNextPageToken] = useState<string | undefined>()
  const [isSermonsLoading, setIsSermonsLoading] = useState<boolean>(false)

  // State to track what sermon is listened to.
  const [selectedSermonToListenTo, setSelectedSermonToListenTo] = useState<
    Sermon | undefined
  >()

  // Used for subsequent pages for infinite scrolling.
  const getSermonListNextPage = useCallback(
    async (params?: URLSearchParams) => {
      const response = await callGetSermonList({
        nextToken: nextPageToken,
        tagId: params?.get('sermonSeries') || undefined,
        match: params?.get('match') || undefined,
      })
      setSermonList([...sermonList, ...response.data.sermons])
      setNextPageToken(response.data.nextToken)
    },
    [nextPageToken]
  )

  // Only load new sermons if the sermon series has changed.
  useEffect(() => {
    const getSermonList = async (params?: URLSearchParams) => {
      const response = await callGetSermonList({
        tagId: params?.get('sermonSeries') || undefined,
        match: params?.get('match') || undefined,
      })
      setSermonList(response.data.sermons)
      setNextPageToken(response.data.nextToken)
      setIsSermonsLoading(false)
    }
    setSelectedSermonToListenTo(undefined)
    setIsSermonsLoading(true)
    getSermonList(searchParams)
  }, [searchParams])

  if (isSermonsLoading) {
    return <CircularProgress color="primary" />
  }

  if (sermonList.length === 0) {
    return <Typography variant="h2">{NO_SERMONS_FOUND}</Typography>
  }

  return (
    <>
      <InfiniteScroll
        dataLength={sermonList.length}
        next={() => getSermonListNextPage(searchParams)}
        loader={<h4>Loading...</h4>}
        hasMore={nextPageToken !== undefined}
        style={{ width: '100%' }}
      >
        {sermonList.map((sermon: Sermon) => {
          return (
            <SermonItem
              key={sermon.id}
              sermon={sermon}
              sermonSeriesTitle={
                idToSermonSeriesMap[sermon.tagId]
                  ? idToSermonSeriesMap[sermon.tagId].title
                  : ''
              }
              selectedSermon={selectedSermonToListenTo}
              handleOnClick={setSelectedSermonToListenTo}
            />
          )
        })}
      </InfiniteScroll>
      <SermonAudioPlayer selectedSermon={selectedSermonToListenTo} />
    </>
  )
}

export default SermonList
