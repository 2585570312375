import { Tabs } from '@mui/material'
import { FC, useState, SyntheticEvent, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import DesktopNavigationTab from './DesktopNavigationTab'
import { NAVIGATION_TAB_OPTIONS } from './tab-types'

const DesktopNavigationTabs: FC = () => {
  const currentUrl = useLocation()

  const getTabIndexFromPath = (path: string): number => {
    return NAVIGATION_TAB_OPTIONS.findIndex((element) => {
      if (element.href) {
        return element.href === path
      }

      return element.subOptions?.find((subOption) =>
        path.includes(subOption.href)
      )
    })
  }

  const [tabValue, setTabValue] = useState(
    getTabIndexFromPath(currentUrl.pathname)
  )

  const handleTabChange = (event: SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue)
  }

  useEffect(() => {
    setTabValue(getTabIndexFromPath(currentUrl.pathname))
  }, [currentUrl])

  return (
    <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary">
      {NAVIGATION_TAB_OPTIONS.map(({ href, label, subOptions }) => (
        <DesktopNavigationTab
          href={href}
          label={label}
          subOptions={subOptions}
          key={label}
        />
      ))}
    </Tabs>
  )
}

export default DesktopNavigationTabs
