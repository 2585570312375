// Sermon page
export const SERMON_PAGE_TITLE = 'Sermons'
export const END_OF_PAGE = 'There are no more sermons to view.'

// Sermon series search
export const SERMON_MENU_ITEM_LABEL = 'sermons'
export const SERMON_SERIES_ONGOING_LABEL = 'Ongoing'
export const SERMON_SERIES_SEARCH_LABEL_TEXT = 'Sermon series'
export const SERMON_SERIES_SEARCH_PLACEHOLDER_TEXT = 'Search by series'

export const SERMON_TITLE_SEARCH_LABEL_TEXT = 'Sermon title'
export const SERMON_TITLE_SEARCH_PLACEHOLDER_TEXT = 'Search by title'

// Sermon item
export const NO_SERMONS_FOUND = 'No sermons were found'
export const SCRIPTURE_LABEL = 'Scripture'
export const SERIES_LABEL = 'Series'
export const TITLE_LABEL = 'Title'
export const SEARCH_BY_LABEL = 'Search by'
export const PREACHER_LABEL = 'Preacher'
export const MISSING_VERSE = 'Selected Passages'
