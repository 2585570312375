import { CardContent, Typography } from '@mui/material'
import { FC } from 'react'
import InfoCard from '../common/InfoCard'
import { DOCTRINAL_STATEMENT_LIST } from './strings'

const DoctrinalStatementSection: FC = () => {
  return (
    <>
      {DOCTRINAL_STATEMENT_LIST.map((statement, index) => {
        return (
          <InfoCard key={statement.title}>
            <CardContent>
              <Typography variant="h3" gutterBottom>
                {index + 1}. {statement.title}
              </Typography>
              <Typography variant="body1">{statement.description}</Typography>
            </CardContent>
          </InfoCard>
        )
      })}
    </>
  )
}

export default DoctrinalStatementSection
