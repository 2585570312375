import { FC } from 'react'
import {
  ADMIN_EMAIL,
  CCF_EMAIL,
  CHILDREN_MINISTRY_EMAIL,
  PLANNING_EMAIL,
} from './string'

type MailToLinkProps = {
  className?: string
  email: string
}

const MailToLink: FC<MailToLinkProps> = ({ className, email }) => {
  return (
    <a
      target="_blank"
      href={`mailto:${email}`}
      className={className}
      rel="noreferrer"
    >
      {email}
    </a>
  )
}

export const PlanningMailToLink: FC = () => (
  <MailToLink email={PLANNING_EMAIL} />
)
export const ChildrenMinistryMailToLink: FC = () => (
  <MailToLink email={CHILDREN_MINISTRY_EMAIL} />
)
export const CcfMailToLink: FC = () => <MailToLink email={CCF_EMAIL} />
export const AdminMailToLink: FC = () => <MailToLink email={ADMIN_EMAIL} />

export default MailToLink
