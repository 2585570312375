import {
  Autocomplete,
  InputAdornment,
  TextField,
  useMediaQuery,
} from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { FC } from 'react'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import SermonSeries from '../../client/types/sermon-series'
import {
  SERMON_MENU_ITEM_LABEL,
  SERMON_SERIES_ONGOING_LABEL,
  SERMON_SERIES_SEARCH_LABEL_TEXT,
  SERMON_SERIES_SEARCH_PLACEHOLDER_TEXT,
} from '../strings'
import customTheme from '../../theme'

type SermonSeriesSearchProps = {
  /**
   * Sermon series to populate the search with.
   */
  sermonSeriesList: SermonSeries[]

  /**
   * Function to run when a sermon series selection changes.
   */
  handleChange: (selectedSermonSeriesId?: string) => void

  /**
   * Define the placeholder text
   */
  placeholderText?: string

  className?: string
}

const SermonSeriesMenuItem = styled.div`
  ${customTheme.breakpoints.up('sm')} {
    display: flex;
    align-items: center;
    white-space: pre-wrap;

    width: 100%;
    padding: 13px 0;
  }
`

const VerticalLine = styled.div`
  /* Dark Gray */
  width: 22px;

  border: 1px solid rgba(71, 71, 71, 0.54);
  transform: rotate(90deg);
`

/**
 * Depending on the type of media, there can be multiple actions done.
 */
const SermonSeriesSearch: FC<SermonSeriesSearchProps> = ({
  sermonSeriesList,
  handleChange,
  placeholderText = SERMON_SERIES_SEARCH_PLACEHOLDER_TEXT,
  className,
}) => {
  const mediaQueryMatches = useMediaQuery(customTheme.breakpoints.down('sm'))

  const onValueChange = (
    event: any,
    selectedSermonSeries: SermonSeries | null
  ) => {
    handleChange(selectedSermonSeries?.tagId)
  }

  return (
    <Autocomplete
      className={className}
      fullWidth
      onChange={onValueChange}
      clearOnEscape
      options={sermonSeriesList}
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.title, inputValue, { insideWords: true })
        const parts = parse(option.title, matches)

        return (
          <li {...props}>
            <SermonSeriesMenuItem>
              {parts.map((part, index) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
              {!mediaQueryMatches && (
                <>
                  <span>
                    <VerticalLine />
                  </span>
                  <span>
                    {option.sermonCount} {SERMON_MENU_ITEM_LABEL}
                  </span>
                  <span>
                    <VerticalLine />
                  </span>
                  <span>
                    {option.endDate
                      ? `${format(parseISO(option.startDate), 'PP')} - ${format(
                          parseISO(option.endDate!!),
                          'PP'
                        )}`
                      : SERMON_SERIES_ONGOING_LABEL}
                  </span>
                </>
              )}
            </SermonSeriesMenuItem>
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={SERMON_SERIES_SEARCH_LABEL_TEXT}
          color="primary"
          placeholder={placeholderText}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default SermonSeriesSearch
