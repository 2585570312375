import { CardContent, Paper } from '@mui/material'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

const StyledPaper = styled(Paper)`
  border-top: 26px #93c15c solid;
  padding: 2%;
`

type InfoBoxProps = {
  children: ReactNode
  variant: any
  className?: string
}

/**
 * An info card has a green border at the top used to put focus on information on the page.
 */
const InfoBox: FC<InfoBoxProps> = ({ children, variant, className }) => {
  return (
    <StyledPaper elevation={0} square variant={variant}>
      <CardContent className={className}>{children}</CardContent>
    </StyledPaper>
  )
}

export default InfoBox
