const LEADERSHIP_DATA = [
  {
    id: 'james-lee',
    name: 'James Lee',
    role: 'Pastor',
    image: 'https://assets.crosslifebiblechurch.org/pastor-james-3.jpg',
    biography: [
      "Hello, my name is James Lee, and I have been serving as the pastor of CrossLife Bible Church ever since it began in 2011. I am married to my beautiful wife, Lucy, who is God's gift to me!",
      "I grew up going to church my entire life, but it was some time in middle school when I came to understand the Gospel. However, a lot of my spiritual growth began at UC Berkeley when I attended a church where the Word of God was preached. I began to shed away my aspirations for monetary success and eventually developed a conviction to go into full-time ministry. After college, I immediately enrolled myself at The Master's Seminary where I sat under the teaching of some of the most brilliant and godly men. I cannot express my gratitude for all the ways in which my professors have counseled, encouraged, and supported me.",
      "When I completed my studies, I went out for a year to do missionary work in Taipei, Taiwan. Being a Korean-American, I didn't know any Chinese and even my Korean left a lot to be desired. However, the Lord was faithful in helping me learn enough of the language to share the Gospel.",
      'After I returned from Taiwan, a small team and I planted a church in Irvine with the support and prayers of local pastors, my close seminary professors, family, and friends, and by the grace of God, the Gospel went forth and produced much fruit! Some came to salvation, while others grew in their faith. Today I am truly blessed with a congregation that loves our Triune God and desires to live out the very Gospel that saved them.',
    ],
    education: [
      'Bachelor of Science in Chemical Engineering and Nuclear Engineering from UC Berkeley (2005)',
      "Master of Divinity from The Master's Seminary (2008)",
    ],
    favoriteVerse:
      '“Restore to me the joy of your salvation, and uphold me with a willing spirit. Then I will teach transgressors your ways, and sinners will return to you” (Psalm 51:12-13 ESV)',
    hobbies: ['Surfing', 'Reading'],
  },
  {
    id: 'matt-defuria',
    name: 'Matt Defuria',
    role: 'Pastor',
    image: 'https://assets.crosslifebiblechurch.org/pastor-matt-2.jpg',
    biography: [
      "Hello, my name is Matt Defuria and I'm a pastor at CrossLife. I have been married to my beautiful wife and high school sweetheart, Amber, since 2015. I have been attending CrossLife since 2012, and was ordained as a pastor in 2022. I wholeheartedly love this church and the people here.",
      "I grew up in a Christian home with parents who love the Lord and wonderfully modeled the Christian walk for me. It's hard to identify the specific time that God saved me, but I believe I saw nascent fruits of understanding and believing the gospel in junior high. Through a combination of my parents' example at home, challenges to my faith from teachers at school, and spiritual encouragement through Amber, it was evident in my high school years that Jesus was my Lord and Savior.",
      "I attended UCI as a nursing major and by God's gracious providence, I stumbled upon CrossLife early on in college. God used the expository preaching of Pastor James, as well as Evangelism Training and the Church Membership Classes, to refine my understanding of the Gospel and the importance of church, and I experienced great growth in my walk with God.",
      "After college, I got married and started working as a cardiac nurse. Yet, not long into my nursing career, God directed my path toward ministry. For the next few years, I worked part-time while attending The Master's Seminary. I graduated in 2020, started working full-time at CrossLife in 2021, and was ordained in 2022. It has truly been a blessing walking alongside God's flock here at CrossLife with a people changed by and committed to the Gospel.",
    ],
    education: [
      'Bachelor of Science in Nursing from UCI (2015)',
      "Master of Divinity from The Master's Seminary (2020)",
      'Certified Biblical Counselor by ACBC (2021)',
    ],
    favoriteVerse:
      '“For the grace of God has appeared, bringing salvation for all people, training us to renounce ungodliness and worldly passions, and to live self-controlled, upright, and godly lives in the present age, waiting for our blessed hope, the appearing of the glory of our great God and Savior Jesus Christ, who gave himself for us to redeem us from all lawlessness and to purify for himself a people for his own possession who are zealous for good works.” (Titus 2:11-14 ESV)',
    hobbies: [
      'Volleyball 🏐',
      'Learning Spanish 🤓',
      'Hanging with family and friends 💞',
      'Reading 📖',
      'Working out 🏋🏽‍♂️',
      'Riding my OneWheel —⚫️—',
      'Calligraphy ✒️',
      'Learning guitar 🎵',
      'Playing some Smash Bros 🎮',
    ],
  },
]

export default LEADERSHIP_DATA
