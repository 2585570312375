import { AppBar, Toolbar, useMediaQuery } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import customTheme from '../theme'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const MenuOptions: FC = () => {
  const matches = useMediaQuery(customTheme.breakpoints.down('sm'))

  if (matches) {
    return <MobileMenu />
  }

  return <DesktopMenu />
}

const StyledToolbar = styled(Toolbar)`
  min-height: 100px;

  ${customTheme.breakpoints.down('sm')} {
    min-height: 75px;
  }
`

const NavBar: FC = () => {
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 6%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
        }}
      >
        <MenuOptions />
      </AppBar>
      <StyledToolbar />
    </>
  )
}

export default NavBar
