import { Typography } from '@mui/material'
import { FC } from 'react'
import Paragraph from '../common/Paragraph'
import ResponsiveContainer from '../common/ResponsiveContainer'

const Giving: FC = () => {
  return (
    <ResponsiveContainer>
      <Typography variant="h1" gutterBottom>
        Giving
      </Typography>
      <Paragraph>
        If you would like to graciously support CrossLife Bible Church, please
        Zelle to &quot;finance@crosslifebiblechurch.org&quot; or write a check
        payable to &quot;CrossLife Bible Church&quot;.
      </Paragraph>
      <Paragraph>
        Please place checks in the offering box at the back of the sanctuary
        after Sunday service.
      </Paragraph>
      <Paragraph> Thank you for your generosity! </Paragraph>
    </ResponsiveContainer>
  )
}

export default Giving
