import { Stack, SxProps, Theme, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

type LabelIconProps = {
  icon: ReactNode
  children: ReactNode
  justifyContent?: string
  className?: string
  sx?: SxProps<Theme>
}

const LabelIcon: FC<LabelIconProps> = ({
  icon,
  children,
  justifyContent,
  className,
  sx,
}) => {
  return (
    <Stack
      alignItems="center"
      justifyContent={justifyContent}
      direction="row"
      sx={sx}
      className={className}
    >
      {icon}
      <Typography variant="body1" sx={{ marginLeft: '5px' }}>
        {children}
      </Typography>
    </Stack>
  )
}

export default LabelIcon
