import { Typography } from '@mui/material'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { SubOptionProps } from '../nav/tab-types'

const StyledTypography = styled(Typography)`
  @media only screen and (min-width: 768px) {
    padding-top: 70px;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 35px;
  }
`

type SectionHeaderProps = {
  /**
   * Section title
   */
  title?: string

  /**
   * Map of url path to bread crumb name
   */
  subOptions?: SubOptionProps[]
}

const SectionHeader: FC<SectionHeaderProps> = ({ title, subOptions }) => {
  const currentUrl = useLocation()

  const subOptionLabel = subOptions?.find(
    (subOption) => subOption.href === currentUrl.pathname
  )?.label

  return (
    <StyledTypography align="center" variant="h2" color="text.primary">
      {title || subOptionLabel}
    </StyledTypography>
  )
}

export default SectionHeader
