/* eslint-disable react/no-danger */
import { CardContent, Typography } from '@mui/material'
import DOMPurify from 'isomorphic-dompurify'
import { FC } from 'react'
import styled from 'styled-components'
import InfoCard from '../common/InfoCard'
import { FAQ_LIST } from './strings'

const StyledAnswer = styled(Typography)`
  ul {
    margin-top: 10px;
    list-style: circle;

    li {
      margin: 0 0 10px 30px;
    }
  }
`

const FaqsSection: FC = () => {
  return (
    <>
      {FAQ_LIST.map((faq, index) => {
        return (
          <InfoCard key={faq.title}>
            <CardContent>
              <Typography variant="h3" gutterBottom>
                {index + 1}. {faq.title}
              </Typography>
              <StyledAnswer
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(faq.description!!),
                }}
              />
            </CardContent>
          </InfoCard>
        )
      })}
    </>
  )
}

export default FaqsSection
