import { CardContent, Paper } from '@mui/material'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

const StyledPaper = styled(Paper)`
  background-color: #f9f7f7;
  margin-bottom: 30px;
`

export type InfoCardContentProps = {
  title?: string
  description?: string
  link?: string
}

type InfoCardProps = {
  children: ReactNode
}

/**
 * A styled card for separating information.
 */
const InfoCard: FC<InfoCardProps> = ({ children }) => {
  return (
    <StyledPaper elevation={0} square variant="secondary">
      <CardContent>{children}</CardContent>
    </StyledPaper>
  )
}

export default InfoCard
