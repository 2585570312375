import { Typography } from '@mui/material'
import { FC } from 'react'
import { CHURCH_SERVICE_TIME } from '../common/string'
import {
  TIME_TITLE,
  LOCATION_TITLE,
  FULL_LOCATION_TEXT_1,
  FULL_LOCATION_TEXT_2,
  FULL_LOCATION_TEXT_3,
} from './strings'

const TimeAndLocationSection: FC = () => {
  return (
    <>
      <Typography variant="h6" align="center">
        {TIME_TITLE}
      </Typography>
      <Typography align="center" marginBottom="25px">
        {CHURCH_SERVICE_TIME}
      </Typography>
      <Typography variant="h6" align="center">
        {LOCATION_TITLE}
      </Typography>
      <Typography align="center" marginBottom="50px">
        {FULL_LOCATION_TEXT_1}
        <br />
        {FULL_LOCATION_TEXT_2}
        <br />
        {FULL_LOCATION_TEXT_3}
      </Typography>
      <iframe
        title="google-maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.3185282602885!2d-117.7763242227636!3d33.674815473301265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdd0de01ded1b%3A0xb15f7868646d5f4c!2s1%20Tarocco%2C%20Irvine%2C%20CA%2092618!5e0!3m2!1sen!2sus!4v1690408506554!5m2!1sen!2sus"
        style={{ border: 0, width: '100%', height: '400px' }}
        aria-hidden="false"
        className="desktop"
        allowFullScreen
      />
    </>
  )
}

export default TimeAndLocationSection
