/* eslint-disable prettier/prettier */
import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@mui/material/styles'

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    primary: true
    secondary: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }
}

let theme = createTheme({
  palette: {
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    primary: {
      main: '#8CA470',
      dark: '#466225',
      contrastText: '#FFFFFF',
    },
    neutral: {
      main: '#FFFFFF',
      dark: '#F9F7F7',
    },
    secondary: {
      main: '#F0F0F0',
      light: '#8E8E8E',
      dark: '#525252',
    },
    text: {
      primary: '#474747',
      secondary: '#FFFFFF',
    },
    action: {
      hover: 'rgba(140, 164, 112, 0.1)',
      selected: '#FFFFFF',
    },
  },
  typography: {
    allVariants: {
      color: '#474747',
    },
    h1: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h4: {
      fontWeight: 300,
      fontSize: '1.5rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    body2: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.5',
    },
    fontFamily: ['Karla', 'Avenir', 'Roboto', 'sans-serif'].join(','),
  },
})

theme = createTheme(theme, {
  components: {
    MuiPaper: {
      variants: [
        {
          props: { variant: 'tertiary' },
          style: {
            backgroundColor: '#FFFFFF',
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            backgroundColor: theme.palette.neutral.dark,
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&$focus': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        focused: {},
      },
    },
  },
} as ThemeOptions)

const customTheme = responsiveFontSizes(theme)

export default customTheme
