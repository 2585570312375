import { Typography } from '@mui/material'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { SubOptionProps } from '../nav/tab-types'
import customTheme from '../theme'

const StyledImage = styled.div`
  background-image: url('https://assets.crosslifebiblechurch.org/new-here-banner.png');
  background-size: cover;

  width: 100%;
  height: 175px;

  ${customTheme.breakpoints.down('sm')} {
    height: 100px;
  }
`

const BlackOverlay = styled.div`
  height: inherit;
  width: inherit;

  background-color: #292929;
  opacity: 0.7;

  display: flex;
  justify-content: center;
  align-items: center;
`

type SectionBannerProps = {
  /**
   * Section title
   */
  title?: string

  /**
   * Map of url path to bread crumb name
   */
  subOptions?: SubOptionProps[]
}

const SectionBanner: FC<SectionBannerProps> = ({ title, subOptions }) => {
  const currentUrl = useLocation()

  const subOptionLabel = subOptions?.find(
    (subOption) => subOption.href === currentUrl.pathname
  )?.label

  return (
    <StyledImage>
      <BlackOverlay>
        <Typography variant="h2" color="text.secondary">
          {title || subOptionLabel}
        </Typography>
      </BlackOverlay>
    </StyledImage>
  )
}

export default SectionBanner
