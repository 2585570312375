/* eslint-disable react/no-danger */
import { CardContent, Typography } from '@mui/material'
import { FC } from 'react'
import InfoCard from '../common/InfoCard'
import { GOSPEL_LIST, GOSPEL_INVITATION_TEXT } from './strings'

const GospelSection: FC = () => {
  return (
    <>
      {GOSPEL_LIST.map((gospelItem) => {
        return (
          <InfoCard key={gospelItem.title}>
            <CardContent>
              <Typography variant="h3" gutterBottom>
                {gospelItem.title}
              </Typography>
              <Typography>{gospelItem.description}</Typography>
            </CardContent>
          </InfoCard>
        )
      })}
      <Typography>{GOSPEL_INVITATION_TEXT}</Typography>
    </>
  )
}

export default GospelSection
